<template>
  <div>
    <div>
      <!--            <v-btn class="ml-2 fl" @click="back()" :disabled="isEditEnterpriseInformation">-->
      <!--                <v-icon small class="mr-1" color="#1090e7">reply</v-icon>-->
      <!--                返回上一级-->
      <!--            </v-btn>-->
      <v-btn
        class="ml-2 fl"
        @click="openEditEnterpriseInformation"
        :disabled="isEditEnterpriseInformation"
      >
        <v-icon small class="mr-1" color="#1090e7">create</v-icon>
        编辑
      </v-btn>

      <el-breadcrumb
        separator-class="el-icon-arrow-right"
        class="fr nav"
        v-if="!isEditEnterpriseInformation"
      >
        <el-breadcrumb-item><a @click="request">首页</a></el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/businessmgt' }"
          >单位认证管理</el-breadcrumb-item
        >
        <el-breadcrumb-item class="text-color">单位详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div style="clear: both;height: 16px"></div>

    <div v-if="isEditEnterpriseInformation">
      <v-card>
        <v-card-title class="authentication-information"
          ><span></span>认证信息</v-card-title
        >

        <v-card-text style="text-align: left"
          >单位名称: {{ enterpriseInformation.name }}</v-card-text
        >

        <v-card-actions>
          <el-form
            :model="enterpriseInformation"
            ref="enterpriseInformation"
            label-width="auto"
            style="width: 100%;"
          >
            <v-row>
              <v-col cols="3">
                <el-form-item label="单位联系人: ">
                  <el-input v-model="enterpriseInformation.contacts"></el-input>
                </el-form-item>
              </v-col>
              <v-col cols="3">
                <el-form-item label="单位法人: ">
                  <el-input v-model="enterpriseInformation.corp"></el-input>
                </el-form-item>
              </v-col>
              <v-col cols="3">
                <el-form-item label="联系方式: ">
                  <el-input
                    v-model="enterpriseInformation.cellphone"
                  ></el-input>
                </el-form-item>
              </v-col>
              <v-col cols="3">
                <el-form-item label="注册日期: ">
                  <el-date-picker
                    v-model="enterpriseInformation.establishTime"
                    type="date"
                    style="max-width: 164px"
                    placeholder="选择日期"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                </el-form-item>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <el-form-item label="单位性质: ">
                  <el-select
                    v-model="enterpriseInformation.nature"
                    placeholder="请选择单位性质"
                  >
                    <el-option label="机关" value="机关"></el-option>
                    <el-option label="事业单位" value="事业单位"></el-option>
                    <el-option label="企业" value="企业"></el-option>
                    <el-option label="学校" value="学校"></el-option>
                    <el-option label="医院" value="医院"></el-option>
                    <el-option label="社会团体" value="社会团体"></el-option>
                  </el-select>
                </el-form-item>
              </v-col>
              <v-col cols="3">
                <el-form-item label="所属行业: ">
                  <el-select
                    v-model="enterpriseInformation.industry"
                    placeholder="请选择行业"
                  >
                    <el-option
                      label="农、林、牧、渔业 "
                      value="农、林、牧、渔业 "
                    ></el-option>
                    <el-option label="采矿业" value="采矿业"></el-option>
                    <el-option label="制造业" value="制造业"></el-option>
                    <el-option
                      label="电力、热力、燃气及水生产和供应业 "
                      value="电力、热力、燃气及水生产和供应业 "
                    ></el-option>
                    <el-option label="建筑业 " value="建筑业"></el-option>
                    <el-option
                      label="交通运输、仓储和邮政业 "
                      value="交通运输、仓储和邮政业"
                    ></el-option>
                    <el-option
                      label="信息传输、软件和信息技术服务业 "
                      value="信息传输、软件和信息技术服务业"
                    ></el-option>
                    <el-option
                      label="批发和零售业 "
                      value="批发和零售业"
                    ></el-option>
                    <el-option
                      label="住宿和餐饮业 "
                      value="住宿和餐饮业"
                    ></el-option>
                    <el-option label="金融业 " value="金融业"></el-option>
                    <el-option label="房地产业" value="房地产业"></el-option>
                    <el-option
                      label="租赁和商务服务业 "
                      value="租赁和商务服务业"
                    ></el-option>
                    <el-option
                      label="科学研究和技术服务业"
                      value="科学研究和技术服务业"
                    ></el-option>
                    <el-option
                      label="水利、环境和公共设施管理业"
                      value="水利、环境和公共设施管理业"
                    ></el-option>
                    <el-option
                      label="居民服务、修理和其他服务业"
                      value="居民服务、修理和其他服务业"
                    ></el-option>
                    <el-option label="教育" value="教育"></el-option>
                    <el-option
                      label="卫生和社会工作 "
                      value="卫生和社会工作"
                    ></el-option>
                    <el-option
                      label="文化、体育和娱乐业"
                      value="文化、体育和娱乐业"
                    ></el-option>
                    <el-option
                      label="公共管理、社会保障和社会组织"
                      value="公共管理、社会保障和社会组织"
                    ></el-option>
                    <el-option label="国际组织" value="国际组织"></el-option>
                  </el-select>
                </el-form-item>
              </v-col>
              <v-col cols="3">
                <el-form-item label="单位银行账号: ">
                  <el-input v-model="enterpriseInformation.bankCard"></el-input>
                </el-form-item>
              </v-col>
              <v-col cols="3">
                <el-form-item label="单位银行账户开户行: ">
                  <el-input
                    v-model="enterpriseInformation.bankDeposit"
                  ></el-input>
                </el-form-item>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <el-form-item label="单位银行账号收款人: ">
                  <el-input v-model="enterpriseInformation.payee"></el-input>
                </el-form-item>
              </v-col>
              <v-col cols="3">
                <el-form-item label="固定电话: ">
                  <el-input
                    v-model="enterpriseInformation.telephone"
                  ></el-input>
                </el-form-item>
              </v-col>
              <v-col cols="3">
                <el-form-item label="注册资金(元): ">
                  <el-input
                    v-model="enterpriseInformation.registeredCapital"
                  ></el-input>
                </el-form-item>
              </v-col>
              <v-col cols="3">
                <el-form-item label="统一社会信用代码: ">
                  <el-input
                    v-model="enterpriseInformation.socialCreditCode"
                  ></el-input>
                </el-form-item>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <el-form-item label="税务所在地: ">
                  <el-select
                    v-model.trim="enterpriseInformation.subarea"
                    placeholder="请选择税务关系所在地"
                  >
                    <el-option label="经开区" value="经开区"></el-option>
                    <el-option label="其他" value="其他"></el-option>
                  </el-select>
                </el-form-item>
              </v-col>
              <v-col cols="3">
                <el-form-item label="是否高新技术单位: ">
                  <el-switch
                    v-model="enterpriseInformation.highTech"
                  ></el-switch>
                </el-form-item>
              </v-col>
              <v-col cols="3">
                <el-form-item label="是否留创项目: ">
                  <el-switch
                    v-model="enterpriseInformation.returneesEntrepreneurial"
                  ></el-switch>
                </el-form-item>
              </v-col>
              <v-col cols="3">
                <el-form-item label="是否规上单位: ">
                  <el-switch
                    v-model="enterpriseInformation.adsEnterprise"
                  ></el-switch>
                </el-form-item>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <el-form-item label="是否建有院士工作站: ">
                  <el-input
                    v-model="enterpriseInformation.academicianWorkstation"
                  ></el-input>
                </el-form-item>
              </v-col>
              <v-col cols="3">
                <el-form-item label="是否建有博士后研究工作站: ">
                  <el-input
                    v-model="enterpriseInformation.postDoctoralResearchCenter"
                  ></el-input>
                </el-form-item>
              </v-col>
              <v-col cols="6">
                <el-form-item label="单位网站: ">
                  <el-input v-model="enterpriseInformation.website"></el-input>
                </el-form-item>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <el-form-item label="单位地址">
                  <el-cascader
                    :options="china"
                    @change="handleResses"
                    v-model="enterpriseInformation.addresses"
                    :placeholder="enterpriseInformation.address"
                    style="width: 300px;float: left"
                  >
                  </el-cascader>
                </el-form-item>
              </v-col>
              <v-col cols="8">
                <el-form-item label="详细地址: ">
                  <input
                    class="input-style"
                    placeholder="请输入详细地址"
                    v-model="enterpriseInformation.details"
                  />
                </el-form-item>
              </v-col>
            </v-row>
          </el-form>
        </v-card-actions>
      </v-card>

      <div class="hr-20"></div>

      <v-card>
        <v-card-actions
          v-for="(item, index) in enterpriseInformation.innovationPlatforms"
          :key="item.id"
        >
          <el-form
            ref="enterpriseInformation"
            label-width="auto"
            style="width: 100%;"
          >
            <v-btn
              class="ml-2 mt-4 fl"
              color="error"
              small
              @click="deleteInnovationPlatforms(item, index)"
            >
              <v-icon small class="mr-1">delete</v-icon>
              删除
            </v-btn>
            <v-row>
              <v-col cols="3">
                <el-form-item label="创新创业平台: ">
                  <el-input v-model="item.platform"></el-input>
                </el-form-item>
              </v-col>
              <v-col cols="3">
                <el-form-item label="认定部门: ">
                  <el-input v-model="item.recognitionDepartment"></el-input>
                </el-form-item>
              </v-col>
              <v-col cols="3">
                <el-form-item label="获评时间: ">
                  <el-date-picker
                    v-model="item.evaluationTime"
                    type="date"
                    placeholder="选择日期"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                </el-form-item>
              </v-col>
              <v-col cols="3">
                <el-form-item label="级别: ">
                  <el-select
                    v-model.trim="item.level"
                    filterable
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="items in level"
                      :key="items.value"
                      :value="items.name"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </v-col>
            </v-row>
          </el-form>
        </v-card-actions>

        <v-card-subtitle>
          <v-btn text color="primary" @click="getAddInnovationPlatforms">
            <v-icon small class="mr-1">add</v-icon>
            新增
          </v-btn>
        </v-card-subtitle>
      </v-card>

      <div class="hr-20"></div>

      <v-row>
        <v-col :cols="7">
          <v-card>
            <v-card-title class="authentication-information"
              ><span></span>单位简介</v-card-title
            >

            <v-card-actions class="content-card">
              <el-form
                :model="enterpriseInformation"
                ref="enterpriseInformation"
                label-width="auto"
                style="width: 100%;"
              >
                <el-form-item>
                  <el-input
                    type="textarea"
                    :row="5"
                    autosize
                    maxlength="500"
                    v-model="enterpriseInformation.introductionContent"
                  ></el-input>
                </el-form-item>
              </el-form>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col :cols="5">
          <v-card>
            <v-card-title class="authentication-information"
              ><span></span>附件材料查看下载</v-card-title
            >

            <v-card-actions class="card-height">
              <el-form
                :model="enterpriseInformation"
                ref="enterpriseInformation"
                label-width="auto"
                style="width: 100%;"
              >
                <el-form-item
                  @click.native="setLabel('工商营业执照')"
                  class="file-card"
                >
                  <el-upload
                    class="upload-demo"
                    accept=".png,.jpg,.jpeg"
                    :action="host + '/file'"
                    :on-success="handleChange"
                    :before-upload="beforeAvatarUpload"
                    :on-remove="handleRemove"
                    :before-remove="beforeRemove"
                    :on-preview="handlePreview"
                    :headers="importHeaders"
                    :file-list="businessCircles"
                    show-file-list
                  >
                    <div>
                      <el-button
                        size="small"
                        round
                        class="file-button"
                        @click="setLabel('工商营业执照')"
                        >点击上传
                      </el-button>
                    </div>

                    <div>
                      <div><span style="color: red">* </span>工商营业执照</div>
                    </div>
                  </el-upload>
                </el-form-item>

                <el-form-item
                  @click.native="setLabel('是否有劳务派遣')"
                  class="file-card"
                >
                  <el-upload
                    accept=".png,.jpg,.jpeg"
                    class="upload-demo"
                    :action="host + '/file'"
                    :before-upload="beforeAvatarUpload"
                    :headers="importHeaders"
                    :on-success="handleChange"
                    :on-remove="handleRemove"
                    :on-preview="handlePreview"
                    :before-remove="beforeRemove"
                    :file-list="laborDispatch"
                  >
                    <div>
                      <el-button
                        size="small"
                        round
                        class="file-button"
                        @click="setLabel('是否有劳务派遣')"
                        >点击上传
                      </el-button>
                    </div>
                    <div>
                      <div>是否有劳务派遣</div>
                    </div>
                  </el-upload>
                </el-form-item>
              </el-form>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <div v-else>
      <v-card>
        <v-card-title class="authentication-information"
          ><span></span>认证信息</v-card-title
        >

        <v-card-text style="text-align: left;font-weight: bold;font-size: 16px"
          >单位名称:
          <span
            style="text-align: left;font-weight: bold;font-size: 16px;color: #000000"
            >{{ enterpriseInformation.name }}</span
          ></v-card-text
        >

        <v-card-actions class="text-content">
          <el-form
            :model="enterpriseInformation"
            ref="enterpriseInformation"
            label-width="auto"
            style="width: 100%;"
          >
            <v-row>
              <v-col cols="3">
                <span>单位联系人:</span>
                {{ enterpriseInformation.contacts }}
              </v-col>
              <v-col cols="3">
                <span>单位法人:</span>
                {{ enterpriseInformation.corp }}
              </v-col>
              <v-col cols="3">
                <span>联系方式:</span>
                {{ enterpriseInformation.cellphone }}
              </v-col>
              <v-col cols="3">
                <span>注册日期:</span>
                <span
                  class="content"
                  v-html="
                    enterpriseInformation.establishTime
                      ? enterpriseInformation.establishTime.substring(0, 10)
                      : enterpriseInformation.establishTime
                  "
                ></span>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col cols="3">
                <span>单位性质:</span>
                {{ enterpriseInformation.nature }}
              </v-col>
              <v-col cols="3">
                <span>所属行业:</span>
                {{ enterpriseInformation.industry }}
              </v-col>
              <v-col cols="3">
                <span>单位银行账号:</span>
                {{ enterpriseInformation.bankCard }}
              </v-col>
              <v-col cols="3">
                <span>单位银行账户开户行:</span>
                {{ enterpriseInformation.bankDeposit }}
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col cols="3">
                <span>单位银行账号收款人:</span>
                {{ enterpriseInformation.payee }}
              </v-col>
              <v-col cols="3">
                <span>固定电话:</span>
                {{ enterpriseInformation.telephone }}
              </v-col>
              <v-col cols="3">
                <span>注册资金(元):</span>
                {{ enterpriseInformation.registeredCapital }}
              </v-col>
              <v-col cols="3">
                <span>统一社会信用代码:</span>
                {{ enterpriseInformation.socialCreditCode }}
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col cols="3">
                <span>税务所在地:</span>
                {{ enterpriseInformation.subarea }}
              </v-col>
              <v-col cols="3">
                <span>是否高新技术单位:</span>
                <span
                  class="content"
                  v-html="enterpriseInformation.highTech === true ? '是' : '否'"
                ></span>
              </v-col>
              <v-col cols="3">
                <span>是否留创项目:</span>
                <span
                  class="content"
                  v-html="
                    enterpriseInformation.returneesEntrepreneurial === true
                      ? '是'
                      : '否'
                  "
                ></span>
              </v-col>
              <v-col cols="3">
                <span>是否规上单位:</span>
                <span
                  class="content"
                  v-html="
                    enterpriseInformation.adsEnterprise === true ? '是' : '否'
                  "
                ></span>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col cols="3">
                <span>是否建有院士工作站:</span>
                {{ enterpriseInformation.academicianWorkstation }}
              </v-col>
              <v-col cols="3">
                <span>是否建有博士后研究工作站:</span>
                {{ enterpriseInformation.postDoctoralResearchCenter }}
              </v-col>
              <v-col cols="6">
                <span>单位网站:</span>
                <a :href="enterpriseInformation.website" target="view_window">{{
                  enterpriseInformation.website
                }}</a>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col cols="12">
                <span>单位地址:</span>
                {{ enterpriseInformation.enterpriseAddress }}
              </v-col>
            </v-row>
          </el-form>
        </v-card-actions>
      </v-card>

      <div class="hr-20"></div>

      <v-card>
        <v-card-actions
          class="text-content"
          v-for="item in enterpriseInformation.innovationPlatforms"
          :key="item.id"
        >
          <el-form
            ref="enterpriseInformation"
            label-width="auto"
            style="width: 100%;"
          >
            <v-row>
              <v-col cols="3">
                <span>创新创业平台:</span>
                {{ item.platform }}
              </v-col>
              <v-col cols="3">
                <span>认定部门:</span>
                {{ item.recognitionDepartment }}
              </v-col>
              <v-col cols="3">
                <span>获评时间:</span>
                <span
                  class="content"
                  v-html="
                    item.evaluationTime
                      ? item.evaluationTime.substring(0, 10)
                      : item.evaluationTime
                  "
                ></span>
              </v-col>
              <v-col cols="3">
                <span>级别:</span>
                {{ item.level }}
              </v-col>
            </v-row>
          </el-form>
        </v-card-actions>
      </v-card>

      <div class="hr-20"></div>

      <v-row>
        <v-col :cols="7">
          <v-card>
            <v-card-title class="authentication-information"
              ><span></span>单位简介</v-card-title
            >

            <v-card-actions>
              <span class="content-card">{{
                enterpriseInformation.introductionContent
              }}</span>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col :cols="5">
          <v-card>
            <v-card-title class="authentication-information"
              ><span></span>附件材料查看下载</v-card-title
            >

            <v-card-text class="card-height">
              <div
                v-for="item in enterpriseInformation.files"
                :key="item.id"
                class="file"
              >
                <v-btn
                  depressed
                  class="see-file"
                  color="primary"
                  @click="previewImage(item.path)"
                >
                  {{ item.memo }}
                </v-btn>
                <v-btn
                  outlined
                  rounded
                  fab
                  x-small
                  class="ml-1"
                  color="primary"
                  @click="getDownloadFile(item)"
                >
                  <v-icon>arrow_downward</v-icon>
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <div class="hr-20"></div>

    <div v-if="isEditEnterpriseInformation">
      <v-btn
        class="ps-6 mr-8"
        color="primary"
        @click="onSubmitEnterpriseInformation"
        >确定修改</v-btn
      >
      <v-btn color="error" @click="getCancelEditEnterpriseInformationDialog"
        >取消修改</v-btn
      >
    </div>

    <div v-else>
      <v-btn
        large
        class="ps-6 mr-4"
        color="primary"
        v-if="enterpriseInformation.status === 2"
        @click="review(21)"
      >
        办 理
      </v-btn>
      <v-btn
        large
        @click="review(20)"
        v-if="enterpriseInformation.status === 2"
      >
        驳 回
      </v-btn>
      <v-btn large disabled v-if="enterpriseInformation.status === 20">
        已驳回
      </v-btn>
      <v-btn large disabled v-if="enterpriseInformation.status === 21">
        已同意
      </v-btn>
    </div>

    <div class="hr-20"></div>

    <!-- 确定退出编辑状态弹窗 -->
    <v-dialog
      :visible.sync="isCancelEditEnterpriseInformationDialog"
      width="30%"
      min-width="200px"
    >
      <v-card>
        <v-card-title>
          单位认证信息
        </v-card-title>

        <v-card-text class="text-content">
          取消的话您刚修改的信息将作废，你确定取消吗？
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="getCancelEditEnterpriseInformation"
          >
            确 定
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="isCancelEditEnterpriseInformationDialog = false"
          >
            取 消
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <el-dialog :visible.sync="zcjlVisible" title="政策经历">
      <el-table :data="gridData">
        <el-table-column
          label="日期"
          property="date"
          width="150"
        ></el-table-column>
        <el-table-column
          label="姓名"
          property="name"
          width="200"
        ></el-table-column>
        <el-table-column label="地址" property="address"></el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog :visible.sync="gzjlVisible" title="工作经历">
      <el-table :data="gridData">
        <el-table-column
          label="日期"
          property="date"
          width="150"
        ></el-table-column>
        <el-table-column
          label="姓名"
          property="name"
          width="200"
        ></el-table-column>
        <el-table-column label="地址" property="address"></el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog title="驳回理由" :visible.sync="dialogVisible" width="520px">
      <el-input
        type="textarea"
        :rows="5"
        v-model="memo"
        placeholder="请输入驳回理由"
        maxlength="50"
      ></el-input>
      <div style="font-size: 12px;float: left;overflow: hidden">
        字数不超过50字
      </div>
      <div style="margin-top: 40px"></div>
      <el-button type="primary" @click="requestReject" class="text"
        >确 定</el-button
      >
      <el-button @click="dialogVisible = false">取 消</el-button>
    </el-dialog>

    <v-dialog v-model="dialogInnovation" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          删除创新创业
        </v-card-title>

        <v-card-text>
          确定要删除该条信息吗(删除后无法恢复)？
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">
            确定
          </v-btn>
          <v-btn color="primary" text @click="dialog = false">
            取消
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog :visible.sync="isReviewDialog" width="30%" min-width="200px">
      <v-card>
        <v-card-title>
          单位认证信息
        </v-card-title>

        <v-card-text class="text-content">
          确定
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="getCancelEditEnterpriseInformation"
          >
            确 定
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="isCancelEditEnterpriseInformationDialog = false"
          >
            取 消
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <el-dialog
      title="预览图片"
      :visible.sync="seePhoto"
      :width="oWidth"
      style="margin-top: -32px"
      :close-on-click-modal="true"
    >
      <v-img :src="addressId" alt="" />
      <iframe
        :src="pdf"
        frameborder="0"
        style="width: 100%; height: 700px"
        v-if="pdf"
      ></iframe>
      <v-btn color="primary" @click="seePhoto = false">
        关闭
      </v-btn>
    </el-dialog>
  </div>
</template>

<script>
import configDate from "../../../utils/config";
import http from "../../../utils/http";
import china from "@/utils/china_regions";
import log from "@/utils/log";

export default {
  components: {
    //headtitle
  },
  data() {
    return {
      /*
            企业信息
             */
      pdf: "",
      oWidth: "600px",
      enterpriseInformation: {
        id: 0,
        name: "", // 单位名称
        contacts: "", // 单位联系人
        corp: "", // 单位法人
        cellphone: "", // 联系方式
        establishTime: "", // 注册日期
        nature: "", // 单位性质
        industry: "", // 所属行业
        bankCard: "", // 单位银行账户
        bankDeposit: "", // 单位银行账户开户行
        payee: "", // 单位银行账号收款人
        telephone: "", // 固定电话
        registeredCapital: "", // 注册资本
        socialCreditCode: "", // 统一社会信用代码
        subarea: "", // 税务所在地
        highTech: false, // 是否高新技术单位
        returneesEntrepreneurial: false, // 是否留创项目
        adsEnterprise: false, // 是否规上单位
        hasAcademicianWorkstation: false, // 是否建有院士工作站
        hasPostDoctoralResearchCenter: false, // 是否建有博士后科研工作站
        academicianWorkstation: "", // 所属院士工作站
        website: "", // 单位网站
        addresses: [], // 单位地址
        address: "",
        details: "", // 单位详细地址
        enterpriseAddress: "",
        introductionContent: "", // 单位简介
        files: [], // 文件
        fileIds: [],
        status: 0, // 单位状态
        platform: "", // 创新创业平台
        recognitionDepartment: "", // 认定平台
        evaluationTime: "", // 获评时间
        level: "", // 级别
        innovationPlatforms: [], // 创新创业平台
      },

      /*
            是否编辑
            */
      isEditEnterpriseInformation: false,
      /*
            取消编辑的弹窗
            */
      isCancelEditEnterpriseInformationDialog: true,
      isReviewDialog: false,
      china: china, // 单位地址选择
      host: configDate.url, // 文件路径
      importHeaders: { token: sessionStorage.getItem("Token") }, // 上传的请求头部
      businessCircles: [], // 工商营业执照
      laborDispatch: [], // 劳务派遣
      dialogVisible: false, // 驳回理由弹窗
      dialogInnovation: false, // 删除创新创业确定弹窗
      seePhoto: false,
      zcjlVisible: false,
      gzjlVisible: false,
      addressId: "",
      addressesId: "",
      memo: "",
      gridData: [],
      /*
            级别
             */
      level: [
        { name: "国家级", value: 11 },
        { name: "省级", value: 12 },
        { name: "市级", value: 13 },
        { name: "区级", value: 14 },
        { name: "其他", value: 15 },
      ],
    };
  },
  created() {
    this.getData(); //初始化数据
    this.enterpriseInformation.id = this.$route.params.id;
    this.Ids = this.$route.params.id;
    this.userId = sessionStorage.getItem("ID");
  },

  methods: {
    getData() {
      let self = this;
      this.$axios
        .get("/enterprise/detail?id=" + this.$route.params.id)
        .then((response) => {
          console.log(response);
          self.enterpriseInformation = response.data.data;
          self.tableData = response.data.data;

          self.enterpriseInformation.addresses.forEach((item) => {
            self.addressesId = item.id;
            self.enterpriseInformation.address =
              "中国" +
              " / " +
              item.province +
              " / " +
              item.city +
              " / " +
              item.county;
            self.enterpriseInformation.enterpriseAddress =
              "中国" +
              " / " +
              item.province +
              " / " +
              item.city +
              " / " +
              item.county +
              " / " +
              item.details;
            self.enterpriseInformation.details = item.details;
          });
          self.businessCircles = [];
          self.laborDispatch = [];
          self.enterpriseInformation.files.forEach((item) => {
            self.enterpriseInformation.fileIds = [];
            self.enterpriseInformation.fileIds.push(item.id);
            switch (item.memo) {
              case "工商营业执照":
                self.businessCircles.push({
                  id: item.id,
                  name: item.name,
                  url: configDate.fileurl + item.path,
                });
                break;
              case "是否有劳务派遣":
                self.laborDispatch.push({
                  id: item.id,
                  name: item.name,
                  url: configDate.fileurl + item.path,
                });
                break;
              default:
                break;
            }
          });
          self.enterpriseInformation.introductionContent =
            response.data.data.introductionContent.content;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    // 点击编辑
    openEditEnterpriseInformation() {
      this.isEditEnterpriseInformation = true;
    },

    //  打开取消编辑提示弹窗
    getCancelEditEnterpriseInformationDialog() {
      this.enterpriseInformation.fileIds = [];
      this.isCancelEditEnterpriseInformationDialog = true;
      this.isEditEnterpriseInformation = false;
      this.businessCircles = [];
      this.laborDispatch = [];
      this.getData();
    },

    //  取消编辑
    getCancelEditEnterpriseInformation() {
      this.isEditEnterpriseInformation = false;
      this.isCancelEditEnterpriseInformationDialog = false;
    },

    //  提交修改了的单位信息
    onSubmitEnterpriseInformation() {
      console.log(this.enterpriseInformation);
      const self = this;
      self.enterpriseInformation.files = self.enterpriseInformation.fileIds;
      let address = [
        {
          city: self.enterpriseInformation.addresses[2],
          county: self.enterpriseInformation.addresses[3],
          details: self.enterpriseInformation.details,
          id: self.addressesId,
          province: self.enterpriseInformation.addresses[1],
        },
      ];
      if (self.enterpriseInformation.addresses[0].city === undefined) {
        self.enterpriseInformation.addresses = address;
      } else {
        self.enterpriseInformation.addresses[0].details =
          self.enterpriseInformation.details;
      }
      self.$axios
        .put("/enterprise", self.enterpriseInformation)
        .then(function(res) {
          if (res.data.status === 200) {
            self.getCancelEditEnterpriseInformationDialog();
            self.getData();
            self.$message({
              message: "更新成功",
              type: "success",
            });
          } else {
            self.$message({
              message: "提交失败",
              type: "waring",
            });
          }
        });
    },

    //  点击查看文件
    previewImage(path) {
      let fileType = path.split(".");
      this.seePhoto = true;
      if (fileType[1] === "pdf") {
        this.addressId = "";
        this.oWidth = "70%";
        this.pdf = configDate.pdffileurl + path;
      } else {
        this.addressId = configDate.fileurl + path;
        this.pdf = "";
        this.oWidth = "600px";
      }
    },

    // 返回上一级
    back() {
      this.$router.back();
    },

    // 添加创新创业平台
    getAddInnovationPlatforms() {
      this.enterpriseInformation.innovationPlatforms.push({
        platform: "", // 创新创业平台
        recognitionDepartment: "", // 认定部门
        evaluationTime: "", // 获评时间
        level: "", // 级别
        isAdd: true,
      });
    },

    // 删除创新创业平台
    deleteInnovationPlatforms(item, index) {
      this.$confirm("确定要删除该条信息吗(删除后无法恢复)？")
        .then((_) => {
          if (item.isAdd) {
            this.enterpriseInformation.innovationPlatforms.splice(index, 1);
          } else {
            this.$axios
              .delete("/platform?idList=" + item.id)
              .then((response) => {
                this.getData();
              });
          }
        })
        .catch((_) => {});
    },

    request() {
      let newPage = this.$router.resolve({
        name: "EntryStoma",
      });
      window.open(newPage.href, "_blank");
    },

    //  驳回
    requestReject() {
      this.$axios
        .put("/enterprise", {
          id: this.Ids,
          memo: this.memo,
          status: 20,
        })
        .then((response) => {
          if (response.data.status === 200) {
            this.$notify({
              title: "成功",
              message: "办理成功",
              type: "success",
            });
            this.dialogVisible = false;
            this.getData();
          } else {
            this.$notify({
              title: "成功",
              message: "办理失败",
              type: "warning",
            });
            this.dialogVisible = false;
          }
        })
        .catch((error) => {
          this.dialogVisible = false;
          console.error(error);
        });
    },

    //设置备用类型
    setLabel(lebalname) {
      this.filelabel = lebalname;
    },

    // 上传成功
    handleChange(response, file, fileList) {
      var self = this;
      if (response.status === 200) {
        response.data.memo = this.filelabel;
        const id = response.data.id;
        let data = {
          id: response.data.id,
          memo: this.filelabel,
        };
        this.$axios.put("/file/record", data).then(function(res) {
          if (res.data.status === 200) {
            self.enterpriseInformation.fileIds.push(id);
            if (res.data.data !== "更新失败") {
              self.$message({
                message: "上传成功",
                type: "success",
              });
            } else {
              self.$message({
                message: "上传失败",
                type: "warning",
              });
            }
          }
        });
      } else {
        this.$message({
          showClose: true,
          message: "上传失败",
          type: "warning",
        });
      }
    },

    // 控制上传文件格式及大小
    beforeAvatarUpload(file) {
      // const isJPG = file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 5;

      // if (!isJPG) {
      //     this.$message.error('图片只能是 png 格式!');
      //     return false
      // }
      if (!isLt2M) {
        this.$message.error("图片大小不能超过 5MB!");
        return false;
      }
    },

    // 删除前
    beforeRemove(file, fileList) {
      // return this.$confirm(`确定删除？`);
    },

    //文件删除
    handleRemove(file, fileList) {
      let id = file.id || file.response.data.id;
      let self = this;
      let newfileId = [];
      this.$axios.delete("/file/delete?id=" + id).then(function(res) {
        if (res.data.status === 200) {
          self.enterpriseInformation.files.forEach(function(arr) {
            if (arr !== id) {
              newfileId.push(arr);
            }
          });
          self.enterpriseInformation.files = newfileId;
        }
      });
    },

    //  点击查看文件
    handlePreview(file) {
      this.seePhoto = true;

      let pdfFile = file.name.split(".");
      if (pdfFile[1] === "pdf") {
        this.oWidth = "70%";
        this.pdf =
          file.url ||
          configDate.url +
            "/file/object/preview?path=" +
            file.response.data.path;
        this.addressId = "";
      } else {
        console.log(file);
        this.addressId =
          file.url ||
          configDate.url + "/file/object?path=" + file.response.data.path;
        this.oWidth = "600px";
        this.pdf = "";
      }
    },

    review(num) {
      if (num === 20) {
        this.dialogVisible = true;
        return false;
      }

      let stex = num === 21 ? "确定要申请通过吗?" : "确定要驳回申请吗?";
      this.$confirm(stex, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .put("/enterprise/check", {
              handlerId: this.userId,
              status: num,
              enterpriseId: this.Ids,
            })
            .then((response) => {
              this.getData();
              if (response.data.status === 200) {
                this.$notify({
                  title: "成功",
                  message: "办理成功",
                  type: "success",
                });
                this.$router.push("/businessmgt");
              } else {
                this.$notify({
                  title: "失败",
                  message: "办理失败",
                  type: "warning",
                });
              }
            })
            .catch((error) => {
              console.error(error);
            });
        })
        .catch(() => {
          this.$notify({
            message: "取消办理",
            type: "info",
          });
        });
    },

    // 下载文件
    getDownloadFile(item) {
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = configDate.url + "/file/object?path=" + item.path;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    //  下载全部文件
    download() {
      const self = this;
      this.$axios.get("/file/object?path=" + self.path).then((response) => {
        window.location.href = self.fileurl + self.path;
      });
    },

    // 单位地址
    handleResses(value) {
      this.enterpriseInformation.addresses = value;
    },
  },
};
</script>

<style lang="less" scoped>
.authentication-information {
  color: #1090e7;
  font-weight: bold;
  position: relative;

  span {
    position: absolute;
    left: 0;
    display: inline-block;
    height: 24px;
    width: 3px;
    background-color: #1090e7;
  }
}

.text-content {
  text-align: left;
  margin: 0 8px;

  span {
    color: #8a8f97;
  }

  .content {
    color: #2a3746;
  }
}

.nav {
  margin-top: 12px;
  color: #2a3746;

  a {
    color: #2a3746;
  }
  margin-right: 8px;
}

.occupy {
  position: relative;
  background-color: red;
}

.content-card {
  margin: 0 16px 16px 16px;
  text-align: left;
  width: 98%;
}

.card-height {
  margin: 0 8px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  .file {
    min-width: 240px;
    height: 48px;

    .see-file {
      min-width: 160px;
    }
  }

  .file-card {
    display: inline-block;
    vertical-align: top;
    width: 256px;
  }

  .upload-demo {
    width: 240px;
    border: 1px solid #0c94ea;
    border-radius: 8px;
    padding: 8px;

    .file-button {
      color: #0c94ea;
    }
  }
}

.input-style {
  float: left;
  width: 100%;
  height: 40px;
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid #dcdcdc;
}

.text {
  color: #ffffff;
}
</style>
